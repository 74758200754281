import React from 'react';
import './App.css';

const projects = [
  {
    title: 'Project 1: Web App',
    description: 'A responsive web application built with React and Node.js.',
    link: 'https://example.com/project1',
  },
  {
    title: 'Project 2: Mobile App',
    description: 'An Android app developed using Flutter and Dart.',
    link: 'https://example.com/project2',
  },
  {
    title: 'Project 3: Machine Learning',
    description: 'A machine learning model for image classification using Python.',
    link: 'https://example.com/project3',
  },
];

const App = () => {
  return (
      <div className="App">
        <header className="App-header">
          <h1>My Portfolio</h1>
          <p>Hello! I'm [Your Name], a passionate developer.</p>
        </header>

        <section className="about-me">
          <h2>About Me</h2>
          <p>
            I'm a software developer with a passion for building scalable and
            efficient applications. I love to experiment with new technologies
            and work on challenging projects.
          </p>
        </section>

        <section className="projects">
          <h2>My Projects</h2>
          <div className="project-list">
            {projects.map((project, index) => (
                <div key={index} className="project-card">
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <a href={project.link} target="_blank" rel="noopener noreferrer">
                    View Project
                  </a>
                </div>
            ))}
          </div>
        </section>

        <footer>
          <p>Connect with me on <a href="https://linkedin.com">LinkedIn</a></p>
        </footer>
      </div>
  );
};

export default App;